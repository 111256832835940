
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class DeclaracionInicioInves extends Vue {
  @Prop({ required: false })
  private visible!: boolean;
  private idComuna: any;
  private bTerminos: boolean = false;

  private terminos: any = "";

  @Watch("visible")
  private watchVisible(newValue: any) {
    this.bTerminos = newValue;
  }
  private created() {
    this.idComuna = process.env.VUE_APP_ID_COMUNA;
  }

  private mounted() {
    this.$axios
      .get(process.env.VUE_APP_AWS_ASSETS + "/static/tramite_inicio_investigacion.html")
      .then(response => {
        this.terminos = response.data;
      })
      .catch(error => {
        this.terminos = "";
      });
  }

  private cerrarDeclaracion(value: any) {
    if (value == true) {
      this.$emit("closeDeclaracion", true);
      this.bTerminos = false;
    } else if (value == false) {
      this.$emit("closeDeclaracion", false);
      this.bTerminos = false;
    } else {
      this.$emit("closeDeclaracion", null);
      this.bTerminos = false;
    }
  }
}
